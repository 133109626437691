import { getDefaultPaginationFilters } from "helpers/Functions";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import { TX_PAY_FILTER_ITEMS } from "../../../helpers/const";

export interface LayoutContextModel {
  filter: any;
  setFilter: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  ledgers: Array<any>;
  setLedgers: (data: any) => void;

  items: Array<any>;
  setItems: (data: any) => void;

  TXPayFilterHandle: any;
  setTXPayFilterHandle: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;
}

const TXContext = createContext<LayoutContextModel>({
  filter: [],
  setFilter: (data: any) => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  ledgers: [],
  setLedgers: (data: any) => {},

  items: [],
  setItems: (data: any) => {},

  TXPayFilterHandle: {},
  setTXPayFilterHandle: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},
});

const TXProvider = ({ children }: PropsWithChildren) => {
  const [filter, setFilter] = useState<any>("new");
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveTxPayFilters;

  let txpayItems = getHeaderFilter(
    "saveTxPayFilters",
    isFilterExistInDB,
    TX_PAY_FILTER_ITEMS
  );

  if (isGodAdmin) {
    // NOTHING CHANGE
  } else {
    txpayItems = txpayItems?.filter((item: any) => item.name !== "All teams");
  }

  const [filterItems, setFilterItems] = useState(() => {
    // GET DRAGGABLE FILTERS DATA FROM LOCAL STORAGE
    // const saved: any = localStorage.getItem("saveTxPayFilters");

    // let items: any = isGodAdmin
    //   ? JSON.parse(saved) || TX_PAY_FILTER_ITEMS
    //   : JSON.parse(saved) || TX_PAY_FILTER_ITEMS;
    // return isGodAdmin
    //   ? items
    //   : items?.filter((item: any) => item.name !== "All teams");
    // return JSON.parse(saved) || TX_PAY_FILTER_ITEMS;
    return txpayItems;
  });
  const [draggedItems, setDraggedItems] = useState(filterItems);

  const [ledgers, setLedgers] = useState([]);
  const [items, setItems] = useState([]);
  const [TXPayFilterHandle, setTXPayFilterHandle] = useState<any>(
    getDefaultPaginationFilters(50)
  );

  const value: LayoutContextModel = {
    filter,
    setFilter,

    filterItems,
    setFilterItems,

    ledgers,
    setLedgers,

    items,
    setItems,

    TXPayFilterHandle,
    setTXPayFilterHandle,

    draggedItems,
    setDraggedItems,
  };

  return <TXContext.Provider value={value}>{children}</TXContext.Provider>;
};

export { TXContext, TXProvider };
