import clsx from "clsx";
import DropDownField from "components/formComponent/DropDownField";
import { Droppable } from "react-beautiful-dnd";

const PreUpload = ({
  eventIndex,
  eventRecordIndex,
  eventRecord,
  instructionLetterTemplates,
  initialTemplateState,
  templateState,
  setTemplateState,
  handleChangeTemplate
}: any) => {
  return (
    <Droppable
      droppableId={`${eventIndex}-${eventRecordIndex}-additionalFile`}
      key={`${eventIndex}-${eventRecordIndex}-additionalFile`}
    >
      {(provided: any, snapshot: any) => {
        return (
          <div
            ref={provided.innerRef}
            className={`   addtional_file_drag ${
              eventRecord?.template?.value === 0 && "!ps-0 !pt-0"
            } w-full `}
          >
            <div
              className="drag_items"
              key={eventRecordIndex}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div
                className={clsx(
                  "px-2 border border-solid rounded w-full text-xs mb-2.5 bg-gray-100/50 flex grid-cols-[25%_1fr_2.5rem_2.5rem] md:grid-cols-[1fr_17%_1fr_2.5rem_2.5rem]",
                  instructionLetterTemplates?.length > 0 && "flex-col",

                  snapshot?.isDraggingOver
                    ? "border-green-500"
                    : "border-violet-500/10",
                  "gap-2.5"
                )}
              >
                <div
                  className={`flex items-center ${
                    instructionLetterTemplates?.length > 0
                      ? "w-full"
                      : "w-[5.6rem]"
                  } gap-2 pt-2 ${eventRecord?.template?.value !== 0 && "pb-2"}`}
                >
                  <p>Additional file</p>

                  {instructionLetterTemplates?.length > 0 && (
                    <DropDownField
                      options={[
                        ...instructionLetterTemplates,
                        {
                          title: "upload your own",
                          value: 0,
                          id: "0",
                        },
                      ]}
                      placeholder="Template"
                      name="template"
                      value={eventRecord?.template}
                      getOptionLabel="title"
                      handleOnChange={(data: any) => {
                        console.log(data?.target?.value);
                        if (data?.target?.value?.value !== 0) {
                          setTemplateState({
                            ...initialTemplateState,
                            status: true,
                            data: data.target.value,
                          });
                        } else {
                          setTemplateState({
                            ...initialTemplateState,
                            status: false,
                          });
                        }
                        setTemplateState({
                          ...initialTemplateState,
                          status: false,
                        });
                        // setTemplate(data.target.value);
                        // setTemplateInputes({});
                        // let canvasBody: any =
                        //   document.getElementsByClassName(
                        //     "offcanvas-body"
                        //   )?.[0];
                        // if (
                        //   canvasBody
                        // ) {
                        //   canvasBody.scrollTop = 0;
                        // }
                        handleChangeTemplate(
                          data
                            ?.target
                            ?.value,
                          eventRecordIndex,
                          eventIndex
                        );
                        // deletePdfRightSide(
                        //   eventRecord?.addtionalFile,
                        //   "additionalFile",
                        //   eventRecordIndex,
                        //   eventIndex
                        // );
                      }}
                      isClearable={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Droppable>
  );
};

export default PreUpload;
